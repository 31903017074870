<template>
    <div class="w-100">
    <label class="w-100 m-0 h6 font-size-label-input-padrao" :class="classes">
	    {{ label }}
    </label>
    <input :type="tipo"
           :list="lista"
           :disabled="disabled"
           class="input-padrao"
           :class="inputClasses"
           :value="value"
           @input="$emit('input', $event.target.value)" />
    </div>
    
</template>

<script>
export default {
    name: 'InputPadrao',
    
    props: {
        classes: {
            type: String,
            default: '' 
        },
        inputClasses: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: '' 
        },
        tipo: {
            type: String,
            default: 'text'
        },
        value: {
            type: [String, Number],
            default: '' 
        },
        lista: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
}
</script>


<style scoped>
.input-padrao {
    background-color: var(--cinza-2);
    color: var(--cinza-5);
    width: 100%;
    border: 1px solid var(--cinza-4);
    outline: none;
}



.input-padrao:hover, .input-padrao:focus {
    outline: none;
}
    .font-size-label-input-padrao {
        font-size: 12px;
        text-align: start !important;
    }
input[type=text]::-webkit-calendar-picker-indicator {
    opacity: 0;
}
</style>
