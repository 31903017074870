<template>
    <button class="btn botao-modal-cancel" v-on:click="$emit('click')">
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: 'BotaoModalCancel',
}
</script>

<style scoped>
.button.botao-modal-cancel {
    background-color: #fff;
    color: #232323;
    border-radius: 0px;
    border-bottom: 2px solid #232323;
}

.button.botao-modal-cancel:hover{
    background: rgba(187, 182, 187, 0.795);
    color: #797676;
}

</style>