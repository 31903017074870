<template>

    <div class="w-100">
        <label class="w-100 m-0 h6 font-size-label-input-select">{{ label }}</label>

        <select class="w-100 input-select"
                v-model="valueInput"
                @change="() => $emit('input', valueInput)">

            <option v-if="placeholder" selected value="">{{ placeholder }}</option>
            <option v-for="(opc, i) in opcoes" v-bind:key="i" :value="opc.value">
                {{ opc.text ? opc.text : opc.value }}
            </option>
        </select>
    </div>
    
</template>


<script>
    export default {
        name: 'input-select',
        props: {
            label: String,
            placeholder: { String, default: 'Selecione' },
            value: {
                type: [String, Number, Boolean],
                default: ''
            },
            opcoes: { Array, default: () => [] }
        },
        data: function () {
            return {
                valueInput: this.value
            }
        },
    }

</script>

<style scoped>
    .font-size-label-input-select {
        font-size: 12px;
        text-align: start !important;
    }
    .input-select {
        background-color: var(--cinza-2);
        color: var(--cinza-5);
        width: 100%;
        border: 1px solid var(--cinza-4);
        outline: none;
    }

        .input-select:focus {
            outline: 0;
        }
</style>